import type { Asset, Entry, EntryFields, Sys } from 'contentful'

import type { PageTemplateFields } from './pageTemplateTypes'

export type MetaData = {
  metadata: { tags: Array<{ sys: Sys }> }
}

export type ComposePageEntry<TemplateFields> = MetaData &
  Sys &
  Entry<ComposePageFields<TemplateFields>>

export type ComposePageFields<TemplateFields = {}> = {
  title: EntryFields.Symbol
  name: EntryFields.Symbol
  slug: EntryFields.Symbol
  publicationDate: string
  content: Entry<TemplateFields>
  seo?: Entry<{
    name: EntryFields.Symbol
    title?: EntryFields.Symbol
    description?: EntryFields.Symbol
    keywords?: EntryFields.Symbol[]
    no_index?: EntryFields.Boolean
    no_follow?: EntryFields.Boolean
    image?: Asset
    twitterCard?: EntryFields.Symbol
  }>
}

export const getComposeEntryProps = <T>(
  composePageOrEntry:
    | ComposePageEntry<T>
    | ComposePageFields<T>
    | undefined
    | null,
): (T | Partial<T>) & {
  composeSlug: string
  publicationDate?: string
} => {
  const entry =
    (composePageOrEntry as ComposePageEntry<T>)?.fields ?? composePageOrEntry

  const fields: T | Partial<T> = entry?.content?.fields ?? {}

  return {
    ...fields,
    composeSlug: entry?.slug ?? '',
    publicationDate: entry?.publicationDate || undefined,
  }
}

export const getComposePageSeoImageUrlAndDescription = (
  composePage?: ComposePageEntry<PageTemplateFields>,
): {
  url?: string
  description?: string
} => {
  if (!composePage || !composePage.fields?.seo)
    return { url: '', description: '' }

  const { seo } = composePage.fields

  if (!seo.fields.image) return { url: '', description: '' }

  return {
    url: seo.fields.image.fields.file.url,
    description: seo.fields.image.fields.description,
  }
}
